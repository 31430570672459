import EventBus from './EventBus';
import {pause, randomNumber, samePosition} from './utils';
import Scanner from './Scanner';
import BagBin from './BagBin';
import BagFeeder from './BagFeeder';
import Bag from './Bag';
import ChargingStation from './ChargingStation';

class IdealDrone {
  x: number;
  y: number;
  speed = 1;
  radius = 10;
  bag?: Bag;
  status: 'idle' | 'docked' | 'docking' | 'loading' | 'unloading' | 'moving' | 'waiting' | 'depleted' | 'charging' =
    'idle';
  preScanner: boolean;
  targetPosition: {x: number; y: number};
  reroute?: {x: number; y: number};
  movementInterval?: number;
  batteryLevel: number = 20;

  constructor(args: {x: number; y: number; preScanner: boolean}) {
    this.x = args.x;
    this.y = args.y;
    this.targetPosition = {x: args.x, y: args.y};
    this.preScanner = args.preScanner;

    EventBus.bagUnloadedFromScanner.on(({bag, idealDrone, device}) => {
      if (idealDrone === this) {
        this.loadBagFromDevice(bag, idealDrone, device);
      }
    });

    EventBus.bagUnloadedFromBagFeeder.on(({bag, idealDrone, device}) => {
      if (idealDrone === this) {
        this.loadBagFromDevice(bag, idealDrone, device);
      }
    });

    EventBus.droneHasDocked.on(({idealDrone, device}) => {
      if (idealDrone === this) {
        if (device instanceof ChargingStation) {
          this.status = 'charging';
        } else {
          this.status = 'docked';
          if (this.bag && (device instanceof Scanner || device instanceof BagBin)) {
            this.unloadBagToDevice(this.bag, idealDrone, device);
          }
        }
      }
    });

    EventBus.droneMoveStarted.on(({startPosition, idealDrone, endPosition}) => {
      if (idealDrone !== this && this.status === 'waiting' && samePosition(startPosition, this.targetPosition)) {
        this.move(this.targetPosition, 'docked');
      }
    });

    EventBus.droneMoveFinished.on((idealDrone) => {
      if (
        idealDrone !== this &&
        this.status !== 'docked' &&
        this.status !== 'charging' &&
        this.status !== 'waiting' &&
        samePosition(this.targetPosition, {x: idealDrone.x, y: idealDrone.y})
      ) {
        this.move(
          {
            x:
              this.targetPosition.x > 1000 || (this.targetPosition.x <= 580 && this.targetPosition.x > 400)
                ? this.targetPosition.x - randomNumber(80)
                : this.targetPosition.x + randomNumber(80),
            y: this.targetPosition.y < 400 ? this.targetPosition.y - 30 : this.targetPosition.y + 30,
          },
          'docking',
          true
        );
      }
    });

    EventBus.droneCharged.on((idealDrone) => {
      if (idealDrone === this) {
        this.batteryLevel = 100;
        this.status = 'idle';
      }
    });
  }

  available(withBag?: boolean) {
    return (
      (withBag ? this.bag : !this.bag) &&
      (this.status === 'idle' || this.status === 'waiting') &&
      (withBag ? true : this.batteryLevel > 10)
    );
  }

  async unloadBagToDevice(bag: Bag, idealDrone: IdealDrone, device: BagBin | Scanner) {
    this.status = 'unloading';
    await pause(1000);
    this.bag = undefined;
    this.status = 'docked';
    EventBus.bagUnloadedFromDrone({bag, idealDrone, device});
    if (device instanceof BagBin || this.preScanner) {
      this.move({x: this.x - 40, y: this.y}, 'idle', false, () => {
        if (this.status === 'depleted') {
          EventBus.batteryDepleted(this);
        }
      });
    } else {
      EventBus.bagUnloadedFromDrone({bag, idealDrone, device});
      this.move({x: this.x + 40, y: this.y}, 'idle', false, () => {
        if (this.status === 'depleted') {
          EventBus.batteryDepleted(this);
        }
      });
    }
  }

  async loadBagFromDevice(bag: Bag, idealDrone: IdealDrone, device: Scanner | BagFeeder) {
    this.status = 'loading';
    await pause(1000);
    bag.currentDevice = this;
    this.bag = bag;
    this.status = 'docked';
    EventBus.bagLoadedOnDrone({bag, idealDrone, device});
    if (device instanceof BagFeeder || !this.preScanner) {
      this.move({x: this.x + 40, y: this.y}, 'idle');
    } else {
      this.move({x: this.x - 40, y: this.y}, 'idle');
    }
  }

  move(
    pos: {x: number; y: number},
    status: 'idle' | 'docked' | 'waiting' | 'depleted' | 'charging' | 'docking',
    reroute?: boolean,
    callback?: () => void
  ) {
    clearInterval(this.movementInterval);
    if (this.batteryLevel <= 10 && !this.bag) {
      this.status = 'depleted';
      if (status !== 'charging') {
        return;
      }
    }
    this.status = 'moving';
    this.batteryLevel = this.batteryLevel - Math.sqrt((this.x - pos.x) ** 2 + (this.y - pos.y) ** 2) / 100;
    EventBus.droneMoveStarted({idealDrone: this, startPosition: this, endPosition: pos});
    if (!reroute) {
      this.targetPosition = pos;
    }
    let prevDiffX = Math.abs(pos.x - this.x);
    let prevDiffY = Math.abs(pos.y - this.y);

    const angleRadians = this.calculateAngleRadians({x: this.x, y: this.y}, pos);

    this.movementInterval = window.setInterval(() => {
      // @ts-ignore
      const newX = this.x + window.bagswarm.speed * Math.cos(angleRadians);
      // @ts-ignore
      const newY = this.y + window.bagswarm.speed * Math.sin(angleRadians);

      const newDiffX = Math.abs(pos.x - newX);
      const newDiffY = Math.abs(pos.y - newY);

      if ((newDiffX > prevDiffX || newDiffX === prevDiffX) && (newDiffY > prevDiffY || newDiffY === prevDiffY)) {
        this.x = pos.x;
        this.y = pos.y;
        clearInterval(this.movementInterval);
        if (!reroute || status === 'idle') {
          this.status = (this.batteryLevel > 10 && !this.bag) || this.bag ? status : 'depleted';
          EventBus.droneMoveFinished(this);
          callback?.();
        } else {
          this.status = 'idle';
        }
      } else {
        this.x = newX;
        this.y = newY;
        prevDiffX = newDiffX;
        prevDiffY = newDiffY;
      }
    }, 10);
  }

  calculateAngleRadians(p1: {x: number; y: number}, p2: {x: number; y: number}) {
    return Math.atan2(p2.y - p1.y, p2.x - p1.x);
  }
}

export default IdealDrone;
