import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Bag from './HardwareEmulators/Bag';
import BagFeeder from './HardwareEmulators/BagFeeder';
import Scanner from './HardwareEmulators/Scanner';
import BagBin from './HardwareEmulators/BagBin';
import IdealDrone from './HardwareEmulators/IdealDrone';
const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

const emptyLog: {type: string; data: unknown}[] = [];
for (let i = 0; i < 30; i++) {
  emptyLog.push({type: '', data: ''});
}

const BagLog = () => {
  const classes = useStyles();
  const [bags, setBags] = useState<Bag[]>([]);

  useEffect(() => {
    // @ts-ignore
    window.setBags = setBags;
    return () => {
      // @ts-ignore
      window.setBags = undefined;
    };
  }, [setBags]);

  const getStatus = useCallback((bag: Bag) => {
    if (bag.currentDevice instanceof BagFeeder) {
      return 'In bag feeder';
    } else if (bag.currentDevice instanceof Scanner) {
      return 'In scanner';
    } else if (bag.currentDevice instanceof BagBin) {
      return 'In bag bin';
    } else if (bag.currentDevice instanceof IdealDrone && bag.currentDevice.preScanner) {
      return 'On drone (P)';
    } else if (bag.currentDevice instanceof IdealDrone && !bag.currentDevice.preScanner) {
      return 'On drone (A)';
    } else if (!bag.currentDevice) {
      return '-';
    } else {
      return 'Unknown status';
    }
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Bag ID</TableCell>
            <TableCell align="right">Destination</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bags.map((bag, index) => (
            <TableRow key={bag.id}>
              <TableCell component="th" scope="row">
                {bag.id}
              </TableCell>
              <TableCell align="right">
                {bag.flight.name}, {bag.flight.destIATA}
              </TableCell>
              <TableCell align="right">{getStatus(bag)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BagLog;
