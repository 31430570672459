import React from 'react';
import Canvas from './Canvas';
import draw from './draw';
import ClippedDrawer from './ClippedDrawer';

const App = () => (
  <ClippedDrawer>
    <Canvas draw={draw} />
  </ClippedDrawer>
);

export default App;
