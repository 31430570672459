// @ts-nocheck
import {hardware} from './HardwareEmulators/FleetControl';

const {idealDrones, scanners, bagBins, bagFeeders, chargingStations} = hardware;

const draw = (context: any, frameCount: any, canvas) => {
  function draw() {
    context.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight);
    context.font = '20px Arial';

    idealDrones.forEach((idealDrone) => {
      if (idealDrone.status === 'depleted') {
        context.fillStyle = 'red';
      } else if (idealDrone.preScanner) {
        context.fillStyle = 'pink';
      } else {
        context.fillStyle = 'skyblue';
      }
      context.strokeStyle = 'gray';
      context.lineWidth = 3;
      context.beginPath();
      context.arc(idealDrone.x, idealDrone.y, idealDrone.radius, 0, Math.PI * 2);
      context.closePath();
      context.fill();
      context.stroke();

      if (idealDrone.status === 'loading' || idealDrone.status === 'unloading') {
        context.fillStyle = 'orange';
        context.strokeStyle = 'gray';
        context.lineWidth = 3;
        context.beginPath();
        context.arc(idealDrone.x, idealDrone.y, idealDrone.radius, 0, Math.PI * 2);
        context.closePath();
        context.fill();
        context.stroke();
      } else if (idealDrone.bag) {
        context.fillStyle = 'brown';
        context.strokeStyle = 'gray';
        context.lineWidth = 3;
        context.beginPath();
        context.arc(idealDrone.x, idealDrone.y, idealDrone.radius / 2, 0, Math.PI * 2);
        context.closePath();
        context.fill();
        context.stroke();
      }
    });

    scanners.forEach((scanner) => {
      if (scanner.statusInput === 'idle') {
        context.fillStyle = 'green';
      } else if (scanner.statusInput === 'docked') {
        context.fillStyle = 'yellow';
      } else {
        context.fillStyle = 'orange';
      }
      context.strokeStyle = 'gray';
      context.lineWidth = 3;
      context.beginPath();
      context.rect(scanner.x, scanner.y, scanner.width / 2, scanner.height);
      context.fill();
      context.stroke();

      if (scanner.statusOutput === 'idle') {
        context.fillStyle = 'green';
      } else if (scanner.statusOutput === 'docked') {
        context.fillStyle = 'yellow';
      } else {
        context.fillStyle = 'orange';
      }
      context.strokeStyle = 'gray';
      context.lineWidth = 3;
      context.beginPath();
      context.rect(scanner.x + scanner.width / 2, scanner.y, scanner.width / 2, scanner.height);
      context.fill();
      context.stroke();

      context.fillStyle = 'black';
      const pos = scanner.getRightDockingPosition();
      context.fillText(String(scanner.bags.length), pos.x - scanner.width / 3, pos.y);
    });

    bagBins.forEach((bagBin) => {
      if (bagBin.status === 'idle') {
        context.fillStyle = 'green';
      } else if (bagBin.status === 'docked') {
        context.fillStyle = 'yellow';
      } else {
        context.fillStyle = 'orange';
      }
      context.strokeStyle = 'gray';
      context.lineWidth = 3;
      context.beginPath();
      context.rect(bagBin.x, bagBin.y, bagBin.width, bagBin.height);
      context.fill();
      context.stroke();
    });

    bagFeeders.forEach((bagFeeder) => {
      if (bagFeeder.status === 'idle') {
        context.fillStyle = 'green';
      } else if (bagFeeder.status === 'docked') {
        context.fillStyle = 'yellow';
      } else {
        context.fillStyle = 'orange';
      }
      context.strokeStyle = 'gray';
      context.lineWidth = 3;
      context.beginPath();
      context.rect(bagFeeder.x, bagFeeder.y, bagFeeder.width, bagFeeder.height);
      context.fill();
      context.stroke();

      context.fillStyle = 'black';
      const pos = bagFeeder.getDockingPosition();
      context.fillText(String(bagFeeder.bags.length), pos.x - bagFeeder.width / 1.5, pos.y);
    });

    chargingStations.forEach((chargingStation) => {
      if (chargingStation.status === 'idle') {
        context.fillStyle = 'red';
      } else {
        context.fillStyle = 'orange';
      }
      context.strokeStyle = 'gray';
      context.lineWidth = 3;
      context.beginPath();
      context.rect(chargingStation.x, chargingStation.y, chargingStation.width, chargingStation.height);
      context.fill();
      context.stroke();
    });
  }

  draw();
};

export default draw;
