import EventBus from './EventBus';
import {pause, samePosition} from './utils';
import IdealDrone from './IdealDrone';
import Bag from './Bag';

class BagFeeder {
  x: number;
  y: number;
  width = 40;
  height = 50;
  bags: Bag[] = [];
  status: 'idle' | 'docked' | 'unloading' = 'idle';
  currentDrone?: IdealDrone;

  constructor(args: {x: number; y: number}) {
    this.x = args.x;
    this.y = args.y;

    EventBus.droneMoveFinished.on(async (idealDrone) => {
      if (
        this.status === 'idle' &&
        samePosition(idealDrone, this.getDockingPosition()) &&
        this.bags.length &&
        !idealDrone.bag
      ) {
        this.status = 'docked';
        this.currentDrone = idealDrone;
        EventBus.droneHasDocked({device: this, idealDrone});
        this.unloadBagToDrone(idealDrone);
      }
    });

    EventBus.droneMoveStarted.on(({idealDrone}) => {
      if (this.currentDrone === idealDrone) {
        this.currentDrone = undefined;
        this.status = 'idle';
        EventBus.droneHasUndocked({device: this, idealDrone});
      }
    });
  }

  async unloadBagToDrone(idealDrone: IdealDrone) {
    if (this.bags.length > 0) {
      this.status = 'unloading';
      const bag = this.bags.shift()!;
      await pause(1000);
      if (samePosition(idealDrone, this.getDockingPosition())) {
        this.status = 'docked';
      } else {
        this.status = 'idle';
      }
      EventBus.bagUnloadedFromBagFeeder({device: this, idealDrone, bag});
    }
  }

  getDockingPosition() {
    return {x: this.x + this.width, y: this.y + this.height / 2};
  }
}

export default BagFeeder;
