import IdealDrone from './IdealDrone';
import Scanner from './Scanner';
import BagBin from './BagBin';
import BagFeeder from './BagFeeder';
import EventBus from './EventBus';
import ChargingStation from './ChargingStation';
import Bag from './Bag';
import {findNearestDevice, randomNumber, samePosition} from './utils';

const bagFeeders = [
  new BagFeeder({x: 0, y: 20}),
  new BagFeeder({x: 0, y: 300}),
  new BagFeeder({x: 0, y: 500}),
  new BagFeeder({x: 0, y: 650}),
];

const scanners = [new Scanner({x: 580, y: 120}), new Scanner({x: 580, y: 350}), new Scanner({x: 580, y: 600})];

const bagBins = [
  new BagBin({x: 1180, y: 20, flight: {name: 'XX123', destIATA: 'ABC'}}),
  new BagBin({x: 1180, y: 80, flight: {name: 'ZF542', destIATA: 'OSL'}}),
  new BagBin({x: 1180, y: 160, flight: {name: 'BR124', destIATA: 'LAX'}}),
  new BagBin({x: 1180, y: 240, flight: {name: 'RD423', destIATA: 'JCH'}}),
  new BagBin({x: 1180, y: 320, flight: {name: 'DO322', destIATA: 'RQW'}}),
  new BagBin({x: 1180, y: 400, flight: {name: 'HA784', destIATA: 'LQN'}}),
  new BagBin({x: 1180, y: 480, flight: {name: 'OR410', destIATA: 'RBP'}}),
  new BagBin({x: 1180, y: 560, flight: {name: 'SS131', destIATA: 'RAC'}}),
  new BagBin({x: 1180, y: 640, flight: {name: 'GU622', destIATA: 'QRC'}}),
  new BagBin({x: 1180, y: 720, flight: {name: 'MZ842', destIATA: 'APH'}}),
];

declare global {
  interface Window {
    bags: Bag[];
  }
}

window.bags = [];

let counter = 0;
const createBag = () => {
  const randomBagOut = bagBins[randomNumber(bagBins.length)];
  return new Bag({id: counter++ /*randomNumber(10 ** 9)*/, flight: randomBagOut.flight});
};

const addBag = (bagFeeder: BagFeeder) => {
  const bag = createBag();
  bag.currentDevice = bagFeeder;
  bagFeeder.bags.push(bag);
  window.bags.push(bag);
  EventBus.bagLoadedOnBagFeeder({device: bagFeeder, bag});
};

const chargingStations = [
  new ChargingStation({x: 300, y: 0}),
  new ChargingStation({x: 300, y: 780}),
  new ChargingStation({x: 900, y: 0}),
  new ChargingStation({x: 900, y: 780}),
];

const idealDrones = [
  new IdealDrone({x: 110, y: 100, preScanner: true}),
  new IdealDrone({x: 120, y: 150, preScanner: true}),
  new IdealDrone({x: 110, y: 200, preScanner: true}),
  new IdealDrone({x: 120, y: 250, preScanner: true}),
  new IdealDrone({x: 110, y: 300, preScanner: true}),
  new IdealDrone({x: 120, y: 350, preScanner: true}),
  new IdealDrone({x: 110, y: 400, preScanner: true}),
  new IdealDrone({x: 120, y: 450, preScanner: true}),
  new IdealDrone({x: 110, y: 500, preScanner: true}),
  new IdealDrone({x: 120, y: 550, preScanner: true}),

  new IdealDrone({x: 710, y: 100, preScanner: false}),
  new IdealDrone({x: 720, y: 150, preScanner: false}),
  new IdealDrone({x: 710, y: 200, preScanner: false}),
  new IdealDrone({x: 720, y: 250, preScanner: false}),
  new IdealDrone({x: 710, y: 300, preScanner: false}),
  new IdealDrone({x: 720, y: 350, preScanner: false}),
  new IdealDrone({x: 710, y: 400, preScanner: false}),
  new IdealDrone({x: 720, y: 450, preScanner: false}),
  new IdealDrone({x: 710, y: 500, preScanner: false}),
  new IdealDrone({x: 720, y: 550, preScanner: false}),
];

// @ts-ignore
window.idealDrones = idealDrones;

export const hardware = {idealDrones, bagBins, scanners, bagFeeders, chargingStations};

EventBus.bagLoadedOnBagBin.on(({bag, device, idealDrone}) => {
  setTimeout(() => {
    window.bags = window.bags.filter((b) => b !== bag);
  }, 5000);
  const randomBagFeeder = bagFeeders[randomNumber(bagFeeders.length)];
  addBag(randomBagFeeder);
});

EventBus.tick.on(() => {
  const bags = ([...window.bags] as Bag[]).filter((bag) => bag);
  // @ts-ignore
  window.setBags?.(bags);
});

EventBus.tick.on(() => {
  const availableDrones = idealDrones.filter((idealDrone) => idealDrone.preScanner && idealDrone.available());
  const unavailableDrones = idealDrones.filter((idealDrone) => idealDrone.preScanner && !idealDrone.available());
  const bagFeederQueue = bagFeeders
    .map((bagFeeder) => bagFeeder.bags)
    .flat()
    .sort((a, b) => (a.id > b.id ? 1 : -1))
    .slice(0, availableDrones.length);
  bagFeederQueue.forEach((bag, index) => {
    const position = (bag.currentDevice! as BagFeeder).getDockingPosition?.();
    if (position) {
      if (
        availableDrones[index].status !== 'waiting' &&
        !unavailableDrones.some((idealDrone) => samePosition(idealDrone, position))
      ) {
        availableDrones[index]?.move(position, 'docking');
      }
    }
  });
});

EventBus.tick.on(() => {
  const availableDrones = idealDrones.filter((idealDrone) => !idealDrone.preScanner && idealDrone.available());
  const unavailableDrones = idealDrones.filter((idealDrone) => !idealDrone.preScanner && !idealDrone.available());
  const scannerQueue = scanners
    .map((scanner) => scanner.bags)
    .flat()
    .sort((a, b) => (a.id > b.id ? 1 : -1))
    .slice(0, availableDrones.length);
  scannerQueue.forEach((bag, index) => {
    const position = (bag.currentDevice! as Scanner).getRightDockingPosition?.();
    if (position) {
      if (
        availableDrones[index].status !== 'waiting' &&
        !unavailableDrones.some((idealDrone) => samePosition(idealDrone, position))
      ) {
        availableDrones[index]?.move(position, 'docking');
      }
    }
  });
});

EventBus.tick.on(() => {
  const availableDrones = idealDrones.filter((idealDrone) => idealDrone.preScanner && idealDrone.available(true));
  const unavailableDrones = idealDrones.filter((idealDrone) => idealDrone.preScanner && !idealDrone.available(true));
  availableDrones.forEach((idealDrone) => {
    const nearestDevice = findNearestDevice(idealDrone, scanners, true);
    if (nearestDevice) {
      if (
        idealDrone.status !== 'waiting' &&
        !unavailableDrones.some((idealDrone) => samePosition(idealDrone, nearestDevice.getLeftDockingPosition()))
      ) {
        idealDrone.move(nearestDevice.getLeftDockingPosition(), 'docking');
      }
    }
  });
});

EventBus.tick.on(() => {
  const availableDrones = idealDrones.filter((idealDrone) => !idealDrone.preScanner && idealDrone.available(true));
  const unavailableDrones = idealDrones.filter((idealDrone) => !idealDrone.preScanner && !idealDrone.available(true));
  availableDrones.forEach((idealDrone) => {
    const nearestDevice = findNearestDevice(idealDrone, bagBins, false);
    if (nearestDevice) {
      if (
        idealDrone.status !== 'waiting' &&
        !unavailableDrones.some((idealDrone) => samePosition(idealDrone, nearestDevice.getDockingPosition()))
      ) {
        idealDrone.move(nearestDevice.getDockingPosition(), 'docking');
      }
    }
  });
});

EventBus.tick.on(() => {
  idealDrones.forEach((idealDrone) => {
    if (idealDrone.status === 'depleted') {
      if (idealDrone.preScanner) {
        idealDrone.move(
          findNearestDevice(idealDrone, chargingStations.slice(0, 2), true)!.getDockingPosition(),
          'charging'
        );
      } else {
        idealDrone.move(
          findNearestDevice(idealDrone, chargingStations.slice(2, 4), false)!.getDockingPosition(),
          'charging'
        );
      }
    }
  });
});

for (let i = 0; i < 10; i++) {
  bagFeeders.forEach((bagIn) => {
    addBag(bagIn);
  });
}

setInterval(() => {
  EventBus.tick();
}, 500);
