import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Button, ButtonGroup, Container, Grid, Slider} from '@material-ui/core';
import BagLog from './BagLog';

const drawerWidth = 400;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
    padding: theme.spacing(3),
    height: '100%',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  grid: {
    height: '100%',
  },
  buttonGroup: {
    width: '100%',
  },
}));

const ClippedDrawer: React.FC = ({children}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Bagswarm
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        classes={{
          paper: classes.drawerPaper,
        }}>
        <Toolbar />
        <div className={classes.drawerContainer}>
          <Grid container direction="row" justifyContent="space-evenly" className={classes.grid}>
            <Grid item xs={12}>
              <Typography id="discrete-slider-small-steps" gutterBottom>
                Load
              </Typography>
              <Slider
                defaultValue={100}
                getAriaValueText={() => ''}
                aria-labelledby="discrete-slider-small-steps"
                step={20}
                marks
                min={0}
                max={500}
                valueLabelDisplay="auto"
              />
              <Typography id="discrete-slider-small-steps" gutterBottom>
                Speed (1 = real time)
              </Typography>
              <Slider
                defaultValue={1}
                getAriaValueText={() => ''}
                aria-labelledby="discrete-slider-small-steps"
                step={0.1}
                marks
                min={0}
                max={5}
                valueLabelDisplay="auto"
                onChange={(event, value) => {
                  // @ts-ignore
                  return (window.bagswarm.speed = value);
                }}
              />
              <Typography id="discrete-slider-small-steps" gutterBottom>
                Events
              </Typography>
              <ButtonGroup color="primary" aria-label="outlined primary button group" className={classes.buttonGroup}>
                <Button>Halted drone</Button>
                <Button>Fire</Button>
                <Button>Late bag</Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <BagLog />
            </Grid>
          </Grid>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Container>
          <>{children}</>
        </Container>
      </main>
    </div>
  );
};

export default ClippedDrawer;
