import {Flight} from '../types';
import Scanner from './Scanner';
import BagBin from './BagBin';
import IdealDrone from './IdealDrone';
import BagFeeder from './BagFeeder';

class Bag {
  id: number;
  flight: Flight;
  currentDevice?: Scanner | BagBin | IdealDrone | BagFeeder;

  constructor(bag: Bag) {
    this.id = bag.id;
    this.flight = bag.flight;
  }
}

export default Bag;
