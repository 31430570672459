import React, {useRef, useEffect} from 'react';
import * as S from './Canvas.styles';

const Canvas = (props: any) => {
  const {draw, ...rest} = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = (canvas as any).getContext('2d');
    let frameCount = 0;
    let animationFrameId: any;

    const render = () => {
      frameCount++;
      draw(context, frameCount, canvas);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  return (
    <S.CanvasContainer>
      <canvas height={800} width={1200} style={{border: '5px solid black'}} ref={canvasRef} {...rest} />
    </S.CanvasContainer>
  );
};

export default Canvas;
