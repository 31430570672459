import {slot, createEventBus} from 'ts-event-bus';
import IdealDrone from './IdealDrone';
import Scanner from './Scanner';
import Bag from './Bag';
import BagBin from './BagBin';
import BagFeeder from './BagFeeder';
import {Position} from '../types';
import ChargingStation from './ChargingStation';

const events = {
  droneMoveStarted: slot<{startPosition: Position; idealDrone: IdealDrone; endPosition: Position}>(),
  droneMoveFinished: slot<IdealDrone>(),
  bagLoadedOnBagFeeder: slot<{device: BagFeeder; bag: Bag}>(),
  bagUnloadedFromBagFeeder: slot<{device: BagFeeder; idealDrone: IdealDrone; bag: Bag}>(),
  bagLoadedOnScanner: slot<{device: Scanner; idealDrone: IdealDrone; bag: Bag}>(),
  bagUnloadedFromScanner: slot<{device: Scanner; idealDrone: IdealDrone; bag: Bag}>(),
  bagLoadedOnDrone: slot<{device: BagFeeder | Scanner; idealDrone: IdealDrone; bag: Bag}>(),
  bagUnloadedFromDrone: slot<{device: Scanner | BagBin; idealDrone: IdealDrone; bag: Bag}>(),
  bagLoadedOnBagBin: slot<{device: BagBin; idealDrone: IdealDrone; bag: Bag}>(),
  init: slot<undefined>(),
  tick: slot(),
  batteryDepleted: slot<IdealDrone>(),
  droneCharged: slot<IdealDrone>(),
  droneHasDocked: slot<{idealDrone: IdealDrone; device: BagFeeder | Scanner | BagBin | ChargingStation}>(),
  droneHasUndocked: slot<{idealDrone: IdealDrone; device: BagFeeder | Scanner | BagBin | ChargingStation}>(),
};

const EventBus = createEventBus({
  events,
});

Object.keys(EventBus)
  .filter((key) => typeof EventBus[key as keyof typeof EventBus] === 'function')
  .map((key) => ({key, fn: EventBus[key as keyof typeof EventBus]}))
  .forEach(({key, fn}) => {
    fn.on.call(EventBus, (args: unknown) => {
      // @ts-ignore
      //console.log(key, args);
      // @ts-ignore
      //if(args?.bag && args?.bag.id === 4) {
      //console.log(key, args);
      //}
      // @ts-ignore
      // if (args?.idealDrone) {
      //   // @ts-ignore
      //   console.log(args.idealDrone.status);
      // }
    });
  });

export default EventBus;
