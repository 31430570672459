import {Position} from '../types';
import ChargingStation from './ChargingStation';
import IdealDrone from './IdealDrone';
import BagBin from './BagBin';
import BagFeeder from './BagFeeder';
import Scanner from './Scanner';

export const samePosition = (position1: Position, position2: Position) =>
  position1.x === position2.x && position1.y === position2.y;

export const pause = (timeout: number) => new Promise((resolve) => setTimeout(resolve, timeout));

export const randomNumber = (upTo: number) => Math.floor(Math.random() * upTo);

export const distance = (pos1: Position, pos2: Position) => Math.sqrt((pos1.x - pos2.x) ** 2 + (pos1.y - pos2.y) ** 2);

export const extractPosition = (
  device: IdealDrone | BagBin | BagFeeder | ChargingStation | Scanner,
  preScanner: boolean
) => {
  if (device instanceof BagBin || device instanceof BagFeeder || device instanceof ChargingStation) {
    return device.getDockingPosition();
  } else if (device instanceof Scanner) {
    return preScanner ? device.getLeftDockingPosition() : device.getRightDockingPosition();
  } else {
    return {x: device.x, y: device.y};
  }
};

export const findNearestPair = <
  TDevice1 extends IdealDrone | BagBin | BagFeeder | ChargingStation | Scanner,
  TDevice2 extends IdealDrone | BagBin | BagFeeder | ChargingStation | Scanner
>(
  devices1: TDevice1[],
  devices2: TDevice2[],
  preScanner: boolean
) => {
  if (devices1.length === 0 || devices2.length === 0) {
    return [undefined, undefined];
  }

  // @ts-ignore
  const closestDevices = (devices1.reduce(
    // @ts-ignore
    (currentClosestPair, current) => {
      const closestDevice = findNearestDevice(current, devices2, preScanner);
      if (!closestDevice) {
        return current;
      }
      const distance1 = distance(current, extractPosition(closestDevice, preScanner));
      if (!currentClosestPair || distance1 < currentClosestPair[2]) {
        return [current, closestDevice, distance1];
      } else {
        return currentClosestPair;
      }
    },
    undefined
  ) || [undefined, undefined, undefined]) as [unknown, unknown, unknown];

  return closestDevices;
};

export const findNearestDevice = <
  TDevice1 extends IdealDrone | BagBin | BagFeeder | ChargingStation | Scanner,
  TDevice2 extends IdealDrone | BagBin | BagFeeder | ChargingStation | Scanner
>(
  device: TDevice1,
  devices: TDevice2[],
  preScanner: boolean
) => {
  if (devices.length === 0) {
    return undefined;
  }
  const devicePosition = extractPosition(device, preScanner);
  // @ts-ignore
  const closestDevice = devices.reduce((current: TDevice2, currentClosest: TDevice2) => {
    if (distance(devicePosition, current) < distance(devicePosition, currentClosest)) {
      return current;
    } else {
      return currentClosest;
    }
  }) as TDevice2;

  return closestDevice;
};
