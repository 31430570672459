import EventBus from './EventBus';
import {pause, samePosition} from './utils';
import IdealDrone from './IdealDrone';

class ChargingStation {
  x: number;
  y: number;
  width = 50;
  height = 20;
  status: 'idle' | 'charging' = 'idle';
  idealDrones: IdealDrone[] = [];

  constructor(args: {x: number; y: number}) {
    this.x = args.x;
    this.y = args.y;

    EventBus.droneMoveFinished.on((idealDrone) => {
      if (samePosition(idealDrone, this.getDockingPosition())) {
        this.idealDrones.push(idealDrone);
        this.status = 'charging';
        EventBus.droneHasDocked({device: this, idealDrone});
        this.chargeDrone(idealDrone);
      }
    });

    EventBus.droneMoveStarted.on(({startPosition, idealDrone}) => {
      if (samePosition(startPosition, this)) {
        this.idealDrones = this.idealDrones.filter((i) => i !== idealDrone);
        if (this.idealDrones.length === 0) {
          this.status = 'idle';
        }
        EventBus.droneHasUndocked({device: this, idealDrone});
      }
    });
  }

  async chargeDrone(idealDrone: IdealDrone) {
    this.status = 'charging';
    await pause(5000);
    EventBus.droneCharged(idealDrone);
  }

  getDockingPosition() {
    return {x: this.x + this.width / 2, y: this.y < 100 ? this.y + this.height : this.y};
  }
}

export default ChargingStation;
