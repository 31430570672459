import EventBus from './EventBus';
import {pause, samePosition} from './utils';
import IdealDrone from './IdealDrone';
import Bag from './Bag';

class Scanner {
  x: number;
  y: number;
  width = 50;
  height = 50;
  bags: Bag[] = [];
  currentInputDrone?: IdealDrone;
  currentOutputDrone?: IdealDrone;
  statusInput: 'idle' | 'docked' | 'loading' = 'idle';
  statusOutput: 'idle' | 'docked' | 'unloading' = 'idle';

  constructor(args: {x: number; y: number}) {
    this.x = args.x;
    this.y = args.y;

    EventBus.droneMoveFinished.on(async (idealDrone) => {
      if (!this.currentInputDrone && samePosition(idealDrone, this.getLeftDockingPosition()) && idealDrone.bag) {
        this.currentInputDrone = idealDrone;
        this.statusInput = 'docked';
        EventBus.droneHasDocked({device: this, idealDrone});
      } else if (
        !this.currentOutputDrone &&
        samePosition(idealDrone, this.getRightDockingPosition()) &&
        this.bags.length > 0 &&
        !idealDrone.bag
      ) {
        this.currentOutputDrone = idealDrone;
        this.statusOutput = 'docked';
        EventBus.droneHasDocked({device: this, idealDrone});
        this.unloadBagToDrone(idealDrone);
      }
    });

    EventBus.bagUnloadedFromDrone.on(({bag, idealDrone, device}) => {
      if (device === this) {
        this.loadBagFromDrone(bag, idealDrone, device);
      }
    });

    EventBus.droneMoveStarted.on(({startPosition, idealDrone}) => {
      if (this.currentInputDrone === idealDrone) {
        this.currentInputDrone = undefined;
        this.statusInput = this.statusInput === 'loading' ? 'loading' : 'idle';
        EventBus.droneHasUndocked({device: this, idealDrone});
      } else if (this.currentOutputDrone === idealDrone) {
        this.currentOutputDrone = undefined;
        this.statusOutput = this.statusOutput === 'unloading' ? 'unloading' : 'idle';
        EventBus.droneHasUndocked({device: this, idealDrone});
      }
    });
  }

  async loadBagFromDrone(bag: Bag, idealDrone: IdealDrone, scanner: Scanner) {
    this.statusInput = 'loading';
    await pause(1000);
    bag.currentDevice = this;
    this.bags.push(bag);
    if (samePosition(idealDrone, this.getLeftDockingPosition())) {
      this.statusInput = 'docked';
    } else {
      this.statusInput = 'idle';
    }
    EventBus.bagLoadedOnScanner({bag, idealDrone, device: scanner});
  }

  async unloadBagToDrone(idealDrone: IdealDrone) {
    if (this.bags.length > 0) {
      this.statusOutput = 'unloading';
      const bag = this.bags.shift()!;
      await pause(1000);
      if (samePosition(idealDrone, this.getRightDockingPosition())) {
        this.statusOutput = 'docked';
      } else {
        this.statusOutput = 'idle';
      }
      EventBus.bagUnloadedFromScanner({device: this, idealDrone, bag});
    }
  }

  getRightDockingPosition() {
    return {x: this.x + this.width, y: this.y + this.height / 2};
  }

  getLeftDockingPosition() {
    return {x: this.x, y: this.y + this.height / 2};
  }
}

export default Scanner;
