import {Flight} from '../types';
import EventBus from './EventBus';
import {pause, samePosition} from './utils';
import IdealDrone from './IdealDrone';
import Bag from './Bag';

class BagBin {
  x: number;
  y: number;
  width = 20;
  height = 30;
  bags: Bag[] = [];
  flight: Flight;
  status: 'idle' | 'docked' | 'loading' = 'idle';
  currentDrone?: IdealDrone;

  constructor(args: {x: number; y: number; flight: Flight}) {
    this.x = args.x;
    this.y = args.y;
    this.flight = args.flight;

    EventBus.droneMoveFinished.on(async (idealDrone) => {
      if (!this.currentDrone && samePosition(idealDrone, this.getDockingPosition()) && idealDrone.bag) {
        this.currentDrone = idealDrone;
        this.status = 'docked';
        EventBus.droneHasDocked({device: this, idealDrone});
      }
    });

    EventBus.droneMoveStarted.on(({idealDrone}) => {
      if (this.currentDrone === idealDrone) {
        this.currentDrone = undefined;
        this.status = 'idle';
        EventBus.droneHasUndocked({device: this, idealDrone});
      }
    });

    EventBus.bagUnloadedFromDrone.on(({bag, idealDrone, device}) => {
      if (device === this) {
        this.loadBagFromDrone(bag, idealDrone, device);
      }
    });
  }

  async loadBagFromDrone(bag: Bag, idealDrone: IdealDrone, bagOut: BagBin) {
    this.status = 'loading';
    await pause(1000);
    bag.currentDevice = this;
    this.bags.push(bag);
    setTimeout(() => {
      this.bags = this.bags.filter((b) => b.id !== bag.id);
    }, 5000);
    if (samePosition(idealDrone, this.getDockingPosition())) {
      this.status = 'docked';
    } else {
      this.status = 'idle';
    }
    EventBus.bagLoadedOnBagBin({bag, idealDrone, device: bagOut});
  }

  getDockingPosition() {
    return {x: this.x, y: this.y + this.height / 2};
  }
}

export default BagBin;
